@font-face {
    font-family: 'Logo';
    src: url('/src/asset/font/Orbitron-VariableFont_wght.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* This ensures text remains visible during web font loading */
}

@font-face {
  font-family: 'NotoSansHK';
  src: url('/src/asset/font/NotoSansHK-SemiBold.ttf') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansHK';
  src: url('/src/asset/font/NotoSansHK-VariableFont_wght.ttf') format('woff2');
  font-style: normal;
  font-display: swap;
}
